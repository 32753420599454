export class RegisteredWidget {
  constructor(public componentType: any) {}
}

export const registeredWidgets: { [name: string]: RegisteredWidget } = {};

export function Widget(name: string): ClassDecorator {
  /* eslint-disable prefer-arrow/prefer-arrow-functions */
  /* eslint-disable @typescript-eslint/ban-types */
  return function(target: Function) {
    registeredWidgets[name] = new RegisteredWidget(target);
  };
}
