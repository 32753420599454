/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpdateSettingEntry } from '../models/update-setting-entry';
import { UpdateSettingsBatchEntry } from '../models/update-settings-batch-entry';
import { ExportSettingsResponse } from '../models/export-settings-response';
import { ImportSettingsEntry } from '../models/import-settings-entry';
import { Setting } from '../models/setting';
import { SettingValuesViewModel } from '../models/setting-values-view-model';
import { SettingHierarchyViewModel } from '../models/setting-hierarchy-view-model';
import { SettingHistoryViewModel } from '../models/setting-history-view-model';
import { SettingEntityInfoViewModel } from '../models/setting-entity-info-view-model';
@Injectable({
  providedIn: 'root'
})
class SettingsService extends __BaseService {
  static readonly refreshSettingsPath = '/api/v1/Settings/RefreshSettings';
  static readonly updateSettingPath = '/api/v1/Settings/{settingId}/UpdateSetting';
  static readonly updateSettingsBatchPath = '/api/v1/Settings/UpdateSettingsBatch';
  static readonly exportSettingsPath = '/api/v1/Settings/ExportSettings';
  static readonly importSettingsPath = '/api/v1/Settings/ImportSettings';
  static readonly getSettingsPath = '/api/v1/Settings';
  static readonly getSettingValuesPath = '/api/v1/Settings/GetSettingValues';
  static readonly getSettingHierarchyPath = '/api/v1/Settings/GetSettingHierarchy';
  static readonly getSettingHistoryPath = '/api/v1/Settings/GetSettingHistory';
  static readonly getSettingEntityInfoPath = '/api/v1/Settings/GetSettingEntityInfo';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param externalWorkCenterId undefined
   */
  refreshSettingsResponse(externalWorkCenterId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (externalWorkCenterId != null) __params = __params.set('externalWorkCenterId', externalWorkCenterId.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/RefreshSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param externalWorkCenterId undefined
   */
  refreshSettings(externalWorkCenterId?: string): __Observable<null> {
    return this.refreshSettingsResponse(externalWorkCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `SettingsService.UpdateSettingParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `entry`:
   */
  updateSettingResponse(params: SettingsService.UpdateSettingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Settings/${encodeURIComponent(String(params.settingId))}/UpdateSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SettingsService.UpdateSettingParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `entry`:
   */
  updateSetting(params: SettingsService.UpdateSettingParams): __Observable<null> {
    return this.updateSettingResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param entry undefined
   */
  updateSettingsBatchResponse(entry: UpdateSettingsBatchEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/UpdateSettingsBatch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  updateSettingsBatch(entry: UpdateSettingsBatchEntry): __Observable<null> {
    return this.updateSettingsBatchResponse(entry).pipe(__map((_r) => _r.body as null));
  }
  exportSettingsResponse(): __Observable<__StrictHttpResponse<ExportSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/ExportSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportSettingsResponse>;
      })
    );
  }
  exportSettings(): __Observable<ExportSettingsResponse> {
    return this.exportSettingsResponse().pipe(__map((_r) => _r.body as ExportSettingsResponse));
  }

  /**
   * @param entry undefined
   */
  importSettingsResponse(entry: ImportSettingsEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Settings/ImportSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  importSettings(entry: ImportSettingsEntry): __Observable<null> {
    return this.importSettingsResponse(entry).pipe(__map((_r) => _r.body as null));
  }
  getSettingsResponse(): __Observable<__StrictHttpResponse<Array<Setting>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Setting>>;
      })
    );
  }
  getSettings(): __Observable<Array<Setting>> {
    return this.getSettingsResponse().pipe(__map((_r) => _r.body as Array<Setting>));
  }

  /**
   * @param params The `SettingsService.GetSettingValuesParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingValuesResponse(
    params: SettingsService.GetSettingValuesParams
  ): __Observable<__StrictHttpResponse<Array<SettingValuesViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.hierarchyType != null) __params = __params.set('hierarchyType', params.hierarchyType.toString());
    if (params.hierarchyLevel != null) __params = __params.set('hierarchyLevel', params.hierarchyLevel.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings/GetSettingValues`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SettingValuesViewModel>>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingValuesParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingValues(params: SettingsService.GetSettingValuesParams): __Observable<Array<SettingValuesViewModel>> {
    return this.getSettingValuesResponse(params).pipe(__map((_r) => _r.body as Array<SettingValuesViewModel>));
  }

  /**
   * @param hierarchyType undefined
   */
  getSettingHierarchyResponse(hierarchyType?: string): __Observable<__StrictHttpResponse<SettingHierarchyViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (hierarchyType != null) __params = __params.set('hierarchyType', hierarchyType.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings/GetSettingHierarchy`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SettingHierarchyViewModel>;
      })
    );
  }
  /**
   * @param hierarchyType undefined
   */
  getSettingHierarchy(hierarchyType?: string): __Observable<SettingHierarchyViewModel> {
    return this.getSettingHierarchyResponse(hierarchyType).pipe(__map((_r) => _r.body as SettingHierarchyViewModel));
  }

  /**
   * @param params The `SettingsService.GetSettingHistoryParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingHistoryResponse(
    params: SettingsService.GetSettingHistoryParams
  ): __Observable<__StrictHttpResponse<Array<SettingHistoryViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.settingId != null) __params = __params.set('settingId', params.settingId.toString());
    if (params.hierarchyType != null) __params = __params.set('hierarchyType', params.hierarchyType.toString());
    if (params.hierarchyLevel != null) __params = __params.set('hierarchyLevel', params.hierarchyLevel.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings/GetSettingHistory`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SettingHistoryViewModel>>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingHistoryParams` containing the following parameters:
   *
   * - `settingId`:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingHistory(params: SettingsService.GetSettingHistoryParams): __Observable<Array<SettingHistoryViewModel>> {
    return this.getSettingHistoryResponse(params).pipe(__map((_r) => _r.body as Array<SettingHistoryViewModel>));
  }

  /**
   * @param params The `SettingsService.GetSettingEntityInfoParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingEntityInfoResponse(
    params: SettingsService.GetSettingEntityInfoParams
  ): __Observable<__StrictHttpResponse<SettingEntityInfoViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.hierarchyType != null) __params = __params.set('hierarchyType', params.hierarchyType.toString());
    if (params.hierarchyLevel != null) __params = __params.set('hierarchyLevel', params.hierarchyLevel.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Settings/GetSettingEntityInfo`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SettingEntityInfoViewModel>;
      })
    );
  }
  /**
   * @param params The `SettingsService.GetSettingEntityInfoParams` containing the following parameters:
   *
   * - `hierarchyType`:
   *
   * - `hierarchyLevel`:
   *
   * - `entityId`:
   */
  getSettingEntityInfo(params: SettingsService.GetSettingEntityInfoParams): __Observable<SettingEntityInfoViewModel> {
    return this.getSettingEntityInfoResponse(params).pipe(__map((_r) => _r.body as SettingEntityInfoViewModel));
  }
}

module SettingsService {
  /**
   * Parameters for UpdateSetting
   */
  export interface UpdateSettingParams {
    settingId: number;
    entry: UpdateSettingEntry;
  }

  /**
   * Parameters for GetSettingValues
   */
  export interface GetSettingValuesParams {
    hierarchyType?: string;
    hierarchyLevel?: string;
    entityId?: string;
  }

  /**
   * Parameters for GetSettingHistory
   */
  export interface GetSettingHistoryParams {
    settingId?: number;
    hierarchyType?: string;
    hierarchyLevel?: string;
    entityId?: string;
  }

  /**
   * Parameters for GetSettingEntityInfo
   */
  export interface GetSettingEntityInfoParams {
    hierarchyType?: string;
    hierarchyLevel?: string;
    entityId?: string;
  }
}

export { SettingsService };
