/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingNotificationService {
  private static notificationSubject = new Subject<[string, boolean]>();
  private static notifications$ = LoadingNotificationService.initNotificationService();

  public static publish(topic: string, loading: boolean): void {
    if (topic) {
      this.notificationSubject.next([topic, loading]);
    }
  }

  private static initNotificationService(): Observable<[string, boolean]> {
    if (!this.notificationSubject) {
      this.notificationSubject = new Subject<[string, boolean]>();
    }

    return LoadingNotificationService.notificationSubject.asObservable();
  }

  public getNotifications(topic: string): Observable<boolean> {
    return LoadingNotificationService.notifications$.pipe(
      filter(([notificationTopic, notification]: [string, boolean]) => notificationTopic === topic),
      map(([notificationTopic, notification]) => notification)
    );
  }
}
