import { Component, OnInit } from '@angular/core';
import { Widget } from '../../dashboard/decorators/widget.decorator';
import { WidgetLayout } from '../../dashboard/models/widget-layout';

@Widget('MachineList')
@Component({
  selector: 'app-machine-list-widget',
  templateUrl: './machine-list-widget.component.html',
  styleUrls: ['./machine-list-widget.component.scss']
})
export class MachineListWidgetComponent implements OnInit {
  constructor() {}
  public widgetConfig: any;
  public widgetLayout: WidgetLayout;

  public ngOnInit(): void {}
}
