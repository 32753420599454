import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListValue } from '../../../models';
import { Dropdown } from 'primeng/dropdown';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'lib-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class DropdownComponent implements ControlValueAccessor, OnInit {
  @Input() public showDefaultValue: boolean;
  @Input() public set defaultValueLabel(newLabel: string) {
    this.defaultOption.label = newLabel;
  }
  @Input() public set options(options: ListValue[]) {
    let finalOptions = options;
    if (this.showDefaultValue) {
      finalOptions = [this.defaultOption].concat(options);
    }

    this.currentOptions = finalOptions;
  }
  @Input() public inlineStyle: any;
  @Input() public disabled = false;
  @Input() public grouped = false;
  @Input() public inputId: string = null;
  @Input() public placeholder: string = null;
  @Input() public appendTo: string = null;
  @Input() public loading = false;
  @Input() public virtualScroll = false;
  @Input() public virtualScrollItemSize = 30;
  @Input() public panelWidth: any;
  @Output() public touched = new EventEmitter<null>();

  public currentOptions: ListValue[] = [];
  public selectedValue: any;

  private propagateChange: (value: any) => void;
  private propagateTouched: () => void;

  private defaultOption: ListValue = {
    label: 'Please select',
    value: null
  };

  constructor() {}

  public ngOnInit(): void {
    if(this.panelWidth) {
      this.panelWidth = this.calculatePanelStyle();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedValue = value;
    }
  }

  public onModelChange(): void {
    this.propagateChange(this.selectedValue);
  }

  public onBlur(): void {
    this.propagateTouched();
    this.touched.emit();
  }

  public focusOption(pDropdown: Dropdown): void {
    //#13440 Assuming this is part of a component method that gets called after the dropdown items are populated
    setTimeout(() => {
      const selected = pDropdown.itemsWrapper.querySelector<HTMLElement>('li[aria-selected="true"]');
      if (selected) {
        selected.setAttribute('tabindex', '-1');
        selected.focus();
        selected.removeAttribute('tabindex');
        pDropdown.accessibleViewChild.nativeElement.focus();
      }
    }, 10); // setTimeout ensures this runs after Angular has finished updating the DOM
  }

  public calculatePanelStyle(): any {
    const minWidth = `min(100vw, ${this.panelWidth})`;
    return { ['min-width']: minWidth };
  }
}
