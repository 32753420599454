import { Component, OnInit } from '@angular/core';
import { Widget, WidgetLayout, WidgetSettings } from '../../dashboard/models';

@Widget('Demo')
@Component({
  selector: 'app-demo-widget',
  templateUrl: './demo-widget.component.html',
  styleUrls: ['./demo-widget.component.scss']
})
export class DemoWidgetComponent implements WidgetSettings, OnInit {
  constructor() {}
  public widgetConfig: any;
  public widgetLayout: WidgetLayout;

  public ngOnInit(): void {}
}
