<h3 mat-dialog-title>
  {{ translationKeyPrefix + data.dialogTitle.replace(' ', '') | translate }}
</h3>
<h4>{{ translationKeyPrefix + 'NavigationSettings' | translate }}:</h4>
<div mat-dialog-actions>
  <form [formGroup]="formCustomSetting">
    <mat-radio-group formControlName="navigationSetting">
      <mat-radio-button value="icononly" class="m-r-10" color="primary">
        {{ translationKeyPrefix + 'IconOnly' | translate }}
      </mat-radio-button>
      <mat-radio-button value="iconandtext" class="m-r-10" color="primary">
        {{ translationKeyPrefix + 'IconAndText' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <br />
    <br />
    <div>
      <button type="submit" mat-raised-button color="primary" (click)="onSave()">
        {{ translationKeyPrefix + 'Save' | translate }}
      </button>
      <button type="reset" mat-raised-button color="primary" (click)="onNoClick()">
        {{ translationKeyPrefix + 'Cancel' | translate }}
      </button>
    </div>
  </form>
</div>
